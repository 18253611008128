.auth-container {
  position: relative;
  min-height: 100vh;
}

.auth-subcontainer {
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(74, 83, 114, 0.08);
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 40px 60px;
  position: absolute;
}

.auth-title {
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  line-height: 29px;
  margin-bottom: 32px;
  text-align: center;
}

.auth-label {
  display: block;
}

.auth-label-text {
  color: #999999;
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
}

.auth-input {
  padding: 8px 16px;
  font-size: 14px;
  color: #333333;
  border-radius: 4px;
  display: block;
  height: 48px;
  border: 1px solid #ced3d6;
  width: 280px;
}

.auth-fields {
  padding-bottom: 30px;
  .auth-label:not(:last-child) {
    margin-bottom: 20px;
  }
}

.auth-button {
  width: 100%;
}
