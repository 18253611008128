.table {
  font-size: 14px;
  width: 100%;
}

.table-row {
  border-radius: 2px;
}

.table-row:first-child {
  border-top: 1px solid #f2f2f2;
}

.table-row:last-child {
  border-bottom: 1px solid #f2f2f2;
}

.table-row:nth-child(2n + 1) {
  background: rgba(244, 247, 251, 0.5);
}

.table-row-name {
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}

.table-row-name-0 {
  display: block;
}

.table-row-name-1 {
  position: relative;
}

.table-row-name-1::after {
  content: '—';
  font-size: 11px;
  margin-right: 3px;
  margin-left: 3px;
}

.table-row-name-1,
.table-row-name-2 {
  font-size: 11px;
  line-height: 11px;
  color: #a5aebd;
}

.table-row-date,
.table-row-name-1,
.table-row-name-2 {
  font-family: Arial;
}

.table-row-date {
  padding-top: 10px;
  font-size: 11px;
  line-height: 15px;
  color: #a5aebd;
}

.table-row-value {
  color: #6f7a98;
  font-size: 14px;
  font-weight: 500;
}

.table-row-value,
.table-row-names {
  padding: 10px;
}

.table-row-value.red {
  color: #f83d3d;
}

.table-row-value,
.table-row-date {
  text-align: center;
}

.table-top-row {
  margin-bottom: 20px;
}

.table-container {
  padding: 0 30px;
  max-height: 300px;
}
.active-facility-table .table-container {
  max-height: none;
}
