.app {
  margin: 0 auto;
  min-height: 100vh;
  padding: 40px 0 70px;
}

.wrapper {
  width: 1300px;
  margin: 0 auto;
}

.main-title {
  color: #000;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  margin-left: 3px;
  font-family: 'MontserratAlternates';
  float: left;
}

.main-button {
  height: 48px;
  color: #ffffff;
  font-size: 16px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  line-height: 21px;
  background: #0079c1;
  letter-spacing: -0.1px;
  padding: 13px 38px 15px 38px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  transition: all 0.25s ease;
}

.main-button:hover {
  background-color: #298ecb;
}

.main-button:active {
  background-color: #0066a2;
}

.main-button:disabled {
  background: #a5aebd;
}

.relative {
  position: relative;
}

.legend-component {
  padding-left: 18px;
}

.legend-component__title {
  color: #6f7a98;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.help-icon__title {
  color: #6f7a98;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.help-icon__subcontainer {
  display: block;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.legend-component__circle {
  content: '';
  position: absolute;
  top: 5px;
  left: -19px;
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-radius: 100%;
  border-style: solid;
}

.legend-component__title:not(:last-child) {
  margin-right: 58px;
}

.dashboard {
  padding-top: 40px;
}

.dashboard-card {
  font-size: 0;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(74, 83, 114, 0.11);
  border-radius: 4px;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div.dashboard-card.loading {
  background: transparent;
  box-shadow: none;
  position: relative;
  margin-top: 0;
  padding: 0;
  height: calc(100vh - 510px);
  min-height: 250px;
}

.dashboard-card:not(:first-child) {
  margin-top: 40px;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.small {
  .bottom-row {
    padding-right: 50px;
  }
  .graph__title {
    height: 35px;
  }
  div.graph {
    width: 280px;
    display: inline-block;
    vertical-align: top;
    height: 267px;
  }
  .dropdown-menu,
  .dropdown-title-single {
    position: absolute;
    bottom: -33px;
    left: 30px;
  }
  .chart-legend {
    display: none;
  }
}

.help-icon__main-container {
  display: inline-block;
  border: 1px solid #e3e3e3;
  padding: 16px 16px 16px 35px;
  background-color: #fff;
  position: absolute;
  right: 4px;
  top: 27px;
  border-radius: 4px;
  transform: translateX(100%);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  transition: all 0.25s ease;
  opacity: 0;
  z-index: -9999;
}

.help-container {
  position: relative;
}
.help-icon__triangle {
  position: relative;
  &::after {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    background-color: #fff;
    transform: rotate(45deg);
    top: -24px;
    border-top: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
    left: -24px;
  }
}
.help-icon {
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
  position: absolute;
  right: -24px;
  top: 1px;
  path {
    transition: all 0.25s ease;
  }
}

.help-icon:hover {
  path {
    fill: #a1a9bc;
    fill-opacity: 1;
  }
  & + .help-icon__main-container {
    z-index: 1;
    opacity: 1;
  }
}

.graph {
  width: 630px;
  position: relative;
  &.xbig,
  &.large {
    width: 100%;
    float: none;
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
}

.graph:first-child {
  float: left;
}

.graph:last-child {
  float: right;
}

.graph__title {
  font-size: 14px;
  padding-left: 30px;
  line-height: 18px;
  color: #000;
  float: left;
  z-index: 999;
  position: relative;
  .no-wrap {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
  }
}

tspan {
  font-family: Arial, sans-serif;
  color: #a5aebd;
  font-size: 11px;
  line-height: 15px;
}

.dashboard-card__top-row {
  height: 400px;
}

.map-container {
  width: auto;
}
.map-container.active {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
}

.header-button:disabled {
  color: #aaa;
}

.header-button:disabled path {
  fill: #ccc;
}

.header-button path {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.header-button.initialFiles {
  margin-right: 61px;
}

.header-button.initialFiles::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -10px;
  width: 1px;
  height: 20px;
  right: -30px;
  background-color: #ced3d6;
}

.header-button.remove:hover path {
  fill: #676f89;
}

.header-button.remove:active path {
  fill: #3e4660;
}

.header-button:not(:disabled).pdf:hover path {
  fill: #e25529;
}

.header-button:not(:disabled).pdf:active path {
  fill: #ba2d00;
}

.header-button:not(:disabled).excel:hover path {
  fill: #299e4a;
}

.header-button:not(:disabled).excel:active path {
  fill: #007521;
}

.common-button,
.header-button {
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #333333;
  padding: 0 0 0 24px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
}

.button-icon {
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  position: absolute;
  background-size: contain;
  background-position: center;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}

.clearfix {
  clear: both;
}

.header {
  font-size: 0;
}

.header-buttons {
  float: right;
  padding-top: 1px;
  padding-right: 1px;
  line-height: 29px;
}

.header-button.excel {
  margin-left: 50px;
  margin-right: 85px;
}

.header-button.export-pdf::before {
  background-image: url(../assets/images/pdf.svg);
}

.header-button.excel::before {
  background-image: url(../assets/images/excel.svg);
}

.header-button.remove::before {
  background-image: url(../assets/images/remove.svg);
}

.header-button.excel::after {
  content: '';
  top: 50%;
  width: 1px;
  right: -31px;
  height: 20px;
  position: absolute;
  transform: translateY(-50%);
  background-color: #ced3d6;
}

.top-row-graph {
  padding-top: 30px;
  margin-right: 10px;
}

.recharts-default-tooltip li {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.recharts-default-tooltip p {
  font-size: 14px;
  color: #6f7a98;
  line-height: 18px;
}

.tooltip-circle,
.tooltip-text {
  display: inline-block;
  vertical-align: middle;
}

.tooltip-circle {
  width: 10px;
  height: 10px;
  line-height: 18px;
  margin-right: 4px;
  border-radius: 50%;
  border: 2px solid #23d692;
}

.recharts-default-tooltip {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e3e3e3 !important;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
  padding: 15px 20px !important;
}

.tooltip-component {
  font-size: 34px;
}

.dropdown-root {
  float: right;
  color: #6f7a98;
  cursor: pointer;
  z-index: 99999;
  position: relative;
  margin-right: 30px;
}

.dropdown-root.top-row-sort {
  display: inline-block;
  float: none;
  vertical-align: middle;
  margin-right: 61px;
  text-align: left;
  position: relative;
  color: #333333;
}

.dropdown-root.top-row-sort::before {
  content: '';
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(../assets/images/sortBy.svg);
}

.dropdown-root.top-row-sort::after {
  content: '';
  top: 50%;
  width: 1px;
  right: -31px;
  height: 20px;
  position: absolute;
  transform: translateY(-50%);
  background-color: #ced3d6;
}

.dropdown-root.top-row-sort .dropdown-title {
  width: 97px;
  text-align: left;
}

.Dropdown-control {
  overflow: hidden;
  padding: 0px 20px;
  position: relative;
  background-color: white;
  box-sizing: border-box;
  transition: all 200ms ease;
}

.Dropdown-arrow-wrapper {
  top: 50%;
  right: 0;
  position: absolute;
  transform: translateY(-50%);
}

.Dropdown-root.is-open .Dropdown-arrow {
  transform: rotate(180deg);
}

.Dropdown-arrow {
  display: block;
  width: 16px;
  height: 16px;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.25s ease;
  background-image: url(../assets/images/menu-down.svg);
}

.dropdown-title {
  width: 150px;
  font-weight: 500;
  text-align: right;
  font-size: 14px;
  line-height: 18px;
  padding-right: 20px;
}

.scrollarea-content {
  margin: 0;
  padding: 0;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
}

.scrollarea {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: 300px;
}

.consumption-graph .scrollarea {
  max-height: none;
}
.scrollarea-content:focus {
  outline: 0;
}

.scrollarea.scrollarea-logs {
  height: calc(100% - 49px);
}
.scrollarea .scrollbar-container {
  position: absolute;
  background: none;
  opacity: 1;
  z-index: 9999;
}

.scrollarea .scrollbar-container.horizontal {
  width: 100%;
  left: 0;
  bottom: 0;
  padding-top: 4px;
  padding-bottom: 0;
  cursor: pointer;
}

.scrollarea .scrollbar-container.horizontal .scrollbar {
  height: 4px;
  position: relative;
  background-color: transparent;
}

.scrollarea .scrollbar-container.horizontal .scrollbar::after {
  content: '';
  position: absolute;
  background: #aaa;
  border-radius: 4px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 8px;
}

.scrollarea .scrollbar-container.vertical {
  height: 100%;
  width: 10px;
  right: 0;
  top: 0;
}

.scrollarea .scrollbar-container.vertical .scrollbar {
  width: 4px;
  height: 20px;
  min-height: 18px;
  position: relative;
  background-color: transparent;
}

.scrollarea .scrollbar-container.vertical .scrollbar::after {
  content: '';
  position: absolute;
  background: #202a3842;
  right: 0;
  min-height: 16px;
  border-radius: 4px;
  left: 0;
  top: 15%;
  bottom: 15%;
}

.dropdown-menu {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.17);
  box-sizing: border-box;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  transform: translateY(10px);
  top: 100%;
  width: 180px;
  height: 180px;
  z-index: 1000;
  right: -20px;
  -webkit-overflow-scrolling: touch;
}

.active .dropdown-arrow {
  transform: rotate(180deg);
}

.dropdown-arrow {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 1px;
  transition: all 0.25s ease;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(../assets/images/menu-down.svg);
}

.Dropdown-menu::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: -1;
  top: 8px;
  right: 35px;
  transform: translateY(-50%) rotate(45deg);
  background-color: #fff;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  font-weight: bold;
  text-transform: capitalize;
}

.dropdown-option {
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  min-width: 180px;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  box-sizing: border-box;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: rgba(178, 193, 230, 0.138);
}

.Dropdown-option.is-selected {
  background-color: rgba(178, 193, 230, 0.138);
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 3s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.cssload-loader {
  position: relative;
  width: 100px;
  height: 100px;
  margin-top: 50px;
  perspective: 780px;
  left: calc(50% - 50px);
  -webkit-perspective: 780px;
}

.cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
}

.cssload-inner.cssload-one {
  left: 0%;
  top: 0%;
  animation: cssload-rotate-one 1.15s linear infinite;
  border-bottom: 3px solid #019ddc;
}

.cssload-inner.cssload-two {
  right: 0%;
  top: 0%;
  animation: cssload-rotate-two 1.15s linear infinite;
  border-right: 3px solid #23d692;
}

.cssload-inner.cssload-three {
  right: 0%;
  bottom: 0%;
  animation: cssload-rotate-three 1.15s linear infinite;
  border-top: 3px solid #0079c1;
}

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-one {
  0% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-ms-keyframes cssload-rotate-one {
  0% {
    -ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-one {
  0% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-two {
  0% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-ms-keyframes cssload-rotate-two {
  0% {
    -ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-two {
  0% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-o-keyframes cssload-rotate-three {
  0% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-ms-keyframes cssload-rotate-three {
  0% {
    -ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-three {
  0% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.loader-second-text,
.loader-text {
  font-size: 14px;
  text-align: center;
  line-height: 18px;
  color: #6f7a98;
}

.loader-second-text {
  margin-top: 4px;
}

.loader-text {
  margin-top: 20px;
}

[class*='hint'] {
  padding: 0 !important;
  background: transparent !important;
  box-shadow: none;
}

.icon-label-container {
  position: relative;
  transition: all 0.25s ease;
}

.icon-label {
  font-size: 14px;
  line-height: 18px;
  color: #4a5372;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-95%, 0);
  text-shadow: -1px 0 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff;
}

.h-content {
  position: relative;
  border-radius: 3px;
  z-index: 9;
  padding: 6px 12px;
  background: rgba(25, 36, 51, 0.8);
}

.h-content__text {
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.table-row-name-0 {
  color: #333333;
  font-weight: 500;
}

.recharts-tooltip-label {
  font-weight: 500;
  margin-bottom: 5px !important;
}

.tooltip-text {
  font-weight: 500;
  color: #333333;
}

div.icon-label-container {
  width: 42px;
  height: 42px;
}

div.icon-label-container.crossroad {
  width: 28px;
  height: 28px;
}

.recharts-tooltip-wrapper.recharts-tooltip-wrapper-right::after {
  left: -12px;
  border-left: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

.recharts-tooltip-wrapper.recharts-tooltip-wrapper-left::after {
  right: -2px;
  border-top: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
}

.recharts-tooltip-wrapper::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  background-color: #fff;
}

.map-button-container {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(74, 74, 74, 0.16);
  border-radius: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  z-index: 99999;
}

.active .resize-map {
  background-image: url(../assets/images/minimize.svg);
}

.resize-map {
  padding: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../assets/images/maximaize.svg);
}

.to-center {
  padding: 0;
  width: 16px;
  height: 16px;
  display: block;
  background-color: #ffffff;
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-image: url(../assets/images/moveToSelection.svg);
  margin-bottom: 13px;
  position: relative;
}

.to-center::after {
  background: #f6f6f6;
  width: 20px;
  height: 1px;
  content: '';
  position: absolute;
  left: -2px;
  bottom: -7px;
}

.splash-title {
  position: absolute;
  font-size: 32px;
  line-height: 24px;
  margin-top: -12px;
  z-index: 99999;
  color: #d7d7d7;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
.graph__title {
  font-weight: 500;
}
.dropdown-title-single {
  float: right;
  color: #6f7a98;
  font-size: 14px;
  line-height: 18px;
  padding-right: 40px;
  font-weight: 500;
}

.dropdown-title-single::first-letter,
.Dropdown-placeholder::first-letter {
  text-transform: uppercase;
}

.no-wrap {
  white-space: nowrap;
}
.cursor-pointer {
  cursor: pointer;
}
/*
 * Container style
 */

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */

.ps__rail-x {
  display: none;
  opacity: 0;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.scrollbar-container .ps__rail-y {
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.scrollbar-container .ps__rail-y::after {
  background-color: transparent;
}

.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  /* there must be 'right' for ps__thumb-y */
  right: 6px;
  /* please don't change 'position' */
  position: absolute;
  width: 4px;
  background: #202a38;
  border-radius: 4px;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  /* height: 11px; */
}
.scrollbar {
  max-height: 200px;
}

/* MS supports */

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.area {
  margin: 0 auto;
  height: 300px;
  background: #e5e5e5;
}

.area .content .item {
  background: #82bb95;
  width: 180px;
  height: 70px;
  margin: 10px;
  float: left;
  padding: 8px;
  box-sizing: border-box;
}

.close-button {
  float: right;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: center;
  border-radius: 50%;
  margin-right: 30px;
  background-color: transparent;
  background-image: url(../assets/images/close.svg);
}

.active-facility-table {
  .scrollbar,
  .scrollarea {
    max-height: none;
  }
  &:not(.fullScreen) {
    .scrollarea-content {
      position: relative;
    }
  }
}

.active-facility-table-title {
  font-size: 11px;
  color: #a5aebd;
  line-height: 15px;
  text-align: left;
}

.active-facility-table-title th {
  padding-bottom: 11px;
  border-bottom: 1px solid #f2f2f2;
}

.active-facility-table-title th:not(:last-child) {
  padding-right: 16px;
}

.active-facility-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 17px;
}

.table-subcontainer {
  background-color: #f9fbfd;
  width: calc(50% - 10px);
  border-radius: 4px;
  padding: 32px;
  margin-bottom: 20px;
  display: inline-block;
}

.table-subcontainer:nth-child(2n + 1):not(:last-child) {
  margin-right: 20px;
}

.table-color-line {
  padding-bottom: 16px;
}

.active-facility-percentage-line-color {
  height: 10px;
  display: inline-block;
  vertical-align: middle;
}

.active-facility-percentage-line-color:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.active-facility-percentage-line-color:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.active-facility-percentage-line-color:not(:last-child) {
  margin-right: 2px;
}

.active-facility-table-row th:not(:last-child):not(:first-child):not(.name) {
  padding-right: 22px;
  white-space: nowrap;
}

.consumer .active-facility-table-row th:first-child {
  padding-right: 12px;
}

.active-facility-value-color {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-left: 14px;
  font-weight: 500;
}

.active-facility-value-color.with-circle::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

$line-colors: (
  0: #0079c1,
  1: #15b6ea,
  2: #23d692,
  3: #82d100,
  4: #fab400,
  5: #ff8e56,
  6: #dc4d58,
  7: #dc4dab,
  8: #944ddc,
  9: #6a4ddc,
);

@each $index, $color in $line-colors {
  .active-facility-value-color.c-#{$index} {
    color: $color;
  }
  .active-facility-value-color.with-circle.c-#{$index}::before {
    border: 2px solid $color;
  }
  .active-facility-color-line.c-#{$index},
  .active-facility-percentage-line-color.c-#{$index} {
    background-color: $color;
  }
}

.active-facility-table-value {
  font-size: 14px;
  line-height: 17px;
  color: #6f7a98;
  text-align: right;
  vertical-align: top;
}

.active-facility-table-value.name {
  padding-right: 16px;
}

.active-facility-table-value.name,
.active-facility-table-value.left {
  text-align: left;
}

.active-facility-table-value:not(:first-child) {
  font-weight: 500;
}

.active-facility-table-row:first-child th {
  padding-top: 16px;
}

.active-facility-table-row th:first-child {
  text-align: left;
}

.active-facility-table-row:not(:last-child) th {
  padding-bottom: 12px;
}

.active-facility-value {
  display: inline-block;
  vertical-align: top;
}

.active-facility-color-line-container {
  display: inline-block;
  width: 160px;
  height: 8px;
  background-color: rgba(201, 204, 213, 0.3);
  border-radius: 100px;
}

.active-facility-color-line {
  height: 8px;
  display: block;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.active-facility-table-title th.producer-title {
  font-size: 14px;
  color: #000000;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  padding-right: 0;
}

.producer .active-facility-table-title {
  text-align: left;
}

.active-facility-table.bottom {
  padding-bottom: 20px;
}

.producer .active-facility-table-value:first-child {
  padding-right: 12px;
}

.table-subcontainer table {
  width: 100%;
}

.fullScreen.dashboard-card {
  position: fixed;
  top: -40px;
  right: 0;
  bottom: 0;
  width: 640px;
  z-index: 9999999;
  overflow: auto;
}

.fullScreen.dashboard-card .table-subcontainer {
  width: 100%;
}

.active .map-button-container.activeFacility {
  right: 656px;
}

.fullScreen.dashboard-card thead .active-facility-table-title th:last-child {
  width: 98px;
  text-align: left;
}

.hideCrossroad .crossroad {
  opacity: 0;
}

.hideFacilities {
  .producer,
  .consumer,
  .storage {
    opacity: 0;
  }
}
