.bottom {
  padding: 40px 10px 40px 10px;
}
.small {
  .bottom-row {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }
}
.bottom-row:not(:last-child) {
  margin-bottom: 50px;
  padding-bottom: 2px;
}
.chart-legend {
  padding-top: 16px;
  padding-left: 49px;
}
