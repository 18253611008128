/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:35 AM */

@font-face {
    font-family: 'MontserratAlternates';
    src: local('MontserratAlternates'),
    url('MontserratAlternates-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro.eot');
    src: local('DIN Pro'), local('DINPro'),
    url('DINPro.eot?#iefix') format('embedded-opentype'),
    url('DINPro.woff') format('woff'),
    url('DINPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Medium.eot');
    src: local('DIN Pro Medium'), local('DINPro-Medium'),
    url('DINPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('DINPro-Medium.woff') format('woff'),
    url('DINPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Bold.eot');
    src: local('DIN Pro Bold'), local('DINPro-Bold'),
    url('DINPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('DINPro-Bold.woff') format('woff'),
    url('DINPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}