.cargo-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.modal-results {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ced3d6;
  border-radius: 4px;
  padding: 22px;
}

.modal.cargo-modal {
  margin-top: 40px;
}
.modal.cargo-modal .modal-results {
  cursor: pointer;
}
.cargo-modal__sub-title {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 37px;
  line-height: 16px;
  color: #999999;
}

.cargo-modal__desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #019ddc;
  padding-left: 24px;
  position: relative;
}

.cargo-modal__desc::after {
  content: '';
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/desc.svg);
}
