.bottom {
  padding: 40px 10px 40px 10px; }

.small .bottom-row {
  width: 50%;
  display: inline-block;
  vertical-align: top; }

.bottom-row:not(:last-child) {
  margin-bottom: 50px;
  padding-bottom: 2px; }

.chart-legend {
  padding-top: 16px;
  padding-left: 49px; }

.table {
  font-size: 14px;
  width: 100%;
}

.table-row {
  border-radius: 2px;
}

.table-row:first-child {
  border-top: 1px solid #f2f2f2;
}

.table-row:last-child {
  border-bottom: 1px solid #f2f2f2;
}

.table-row:nth-child(2n + 1) {
  background: rgba(244, 247, 251, 0.5);
}

.table-row-name {
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}

.table-row-name-0 {
  display: block;
}

.table-row-name-1 {
  position: relative;
}

.table-row-name-1::after {
  content: '—';
  font-size: 11px;
  margin-right: 3px;
  margin-left: 3px;
}

.table-row-name-1,
.table-row-name-2 {
  font-size: 11px;
  line-height: 11px;
  color: #a5aebd;
}

.table-row-date,
.table-row-name-1,
.table-row-name-2 {
  font-family: Arial;
}

.table-row-date {
  padding-top: 10px;
  font-size: 11px;
  line-height: 15px;
  color: #a5aebd;
}

.table-row-value {
  color: #6f7a98;
  font-size: 14px;
  font-weight: 500;
}

.table-row-value,
.table-row-names {
  padding: 10px;
}

.table-row-value.red {
  color: #f83d3d;
}

.table-row-value,
.table-row-date {
  text-align: center;
}

.table-top-row {
  margin-bottom: 20px;
}

.table-container {
  padding: 0 30px;
  max-height: 300px;
}
.active-facility-table .table-container {
  max-height: none;
}

.modal {
  width: 672px;
  padding: 40px;
  border-radius: 4px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(74, 83, 114, 0.08);
}

.modal__title {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 31px;
}

.modal-dropzone-title {
  color: #999999;
  font-size: 14px;
  line-height: 16px;
  -webkit-font-feature-settings: 'pnum' on, 'lnum' on;
          font-feature-settings: 'pnum' on, 'lnum' on;
}

.modal__dropzone-remove-button {
  float: right;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 23px;
  right: 23px;
  padding: 0;
  font-size: 0;
  background-color: transparent;
}
.modal__dropzone-remove-button path {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.modal__dropzone.showLoader {
  cursor: default;
}
.modal__dropzone {
  width: 592px;
  height: 60px;
  margin-top: 9px;
  cursor: pointer;
  border-radius: 4px;
  padding: 20px 22px 20px 21px;
  margin-bottom: 30px;
  border: 1px dashed #b4c4d7;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.modal__dropzone:hover {
  border: 1px solid #b4c4d7;
}
.modal__dropzone.isDragActive {
  border: 1px solid #97a5b5;
}

.modal__dropzone.active {
  border: 1px solid #ced3d6;
}

.modal__dropzone-title-container {
  text-align: center;
  font-size: 0;
}

.modal__dropzone-title-container.active {
  text-align: left;
}

.modal__dropzone-title {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: -0.1px;
}

.modal__dropzone-title-container.active .modal__dropzone-title-icon {
  background-image: url(/files/static/media/dropzone-active.0ccac739.svg);
}

.modal__dropzone-title-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/files/static/media/dropzone-file.921c552c.svg);
  margin-right: 8px;
}

.firstpage-loader {
  height: calc(100vh - 480px);
  position: relative;
}
.modal-top-title {
  font-size: 14px;
  line-height: 16px;
  color: rgba(111, 122, 152, 0.5);
  margin-bottom: 20px;
  font-weight: 500;
}
.modal-top-row {
  width: 672px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 20px;
}
.modal-switch-button {
  box-shadow: 0px 0px 32px rgba(74, 83, 114, 0.08);
  border-radius: 4px;
  background-color: #f4f7fb;
  color: #a5aebd;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  width: 326px;
  text-align: center;
  padding: 20px 0;
}
.modal-switch-button:hover {
  background-color: #fff;
}

.modal-switch-button svg {
  vertical-align: middle;
  margin-right: 12px;
}
.modal-switch-button span {
  vertical-align: middle;
  font-weight: bold;
}
.modal-switch-button.active {
  background-color: #fff;
  color: #0079c1;
}

.modal-switch-button path {
  fill: #a5aebd;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.modal-switch-button.active path {
  fill: #0079c1;
}

.modal-button {
  width: 290px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 11px 0;
  background-color: transparent;
  border-radius: 4px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  color: #a5aebd;
}
.modal-button:hover {
  background-color: #fff;
}

.modal-button.active {
  color: #0079c1;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px rgba(74, 83, 114, 0.08);
}
.modal-buttons-row {
  background: #f4f7fb;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 24px;
}
.project-number-input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ced3d6;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  font-size: 14px;
  color: #333333;
  line-height: 17px;
  padding: 15px 16px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
}

.get-example-button.common-button {
  float: right;
  line-height: 48px;
}

.get-example-button.common-button .button-icon {
  line-height: 25px;
}

.alert-subtitle {
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
}

.alert-title {
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
}

.alert-title,
.alert-desc {
    color: #333333;
}

.alert-desc::first-letter {
    text-transform: uppercase;
}

.alert-desc {
    font-size: 14px;
    line-height: 24px;
}

.alert-desc-medium {
    font-weight: 500;
}

.description-container:not(:last-child) {
    margin-bottom: 16px;
}

.alert {
    background: #FFFFFF;
    box-shadow: 0 0 32px rgba(74, 83, 114, 0.08);
    border-radius: 4px;
    font-size: 0;
    width: 672px;
    margin: 0 auto;
    padding: 24px;
    position: relative;
}

.alert::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 4px;
    border-radius: 4px;
}

.alert.warning::before {
    background-color: #FFD15B;
}

.alert.error::before {
    background-color: #FF5B5B;
}

.alert-icon {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    position: relative;
    margin-right: 16px;
    box-shadow: 0 0 32px rgba(74, 83, 114, 0.08);
}

.alert-icon,
.alert-text {
    display: inline-block;
    vertical-align: top;
}

.alert-text {
    max-width: calc(100% - 48px);
}

.warning .alert-icon {
    background-image: url(/files/static/media/warning.e626c537.svg);
}

.error .alert-icon {
    background-image: url(/files/static/media/error.b3a5a724.svg);
}

.alert-container {
    padding-top: 40px;
}

.alert-title {
    margin-bottom: 8px;
}
.app {
  margin: 0 auto;
  min-height: 100vh;
  padding: 40px 0 70px; }

.wrapper {
  width: 1300px;
  margin: 0 auto; }

.main-title {
  color: #000;
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  margin-left: 3px;
  font-family: 'MontserratAlternates';
  float: left; }

.main-button {
  height: 48px;
  color: #ffffff;
  font-size: 16px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  line-height: 21px;
  background: #0079c1;
  letter-spacing: -0.1px;
  padding: 13px 38px 15px 38px;
  -webkit-font-feature-settings: 'pnum' on, 'lnum' on;
          font-feature-settings: 'pnum' on, 'lnum' on;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.main-button:hover {
  background-color: #298ecb; }

.main-button:active {
  background-color: #0066a2; }

.main-button:disabled {
  background: #a5aebd; }

.relative {
  position: relative; }

.legend-component {
  padding-left: 18px; }

.legend-component__title {
  color: #6f7a98;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  vertical-align: middle;
  display: inline-block;
  position: relative; }

.help-icon__title {
  color: #6f7a98;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px; }

.help-icon__subcontainer {
  display: block; }
  .help-icon__subcontainer:not(:last-child) {
    margin-bottom: 12px; }

.legend-component__circle {
  content: '';
  position: absolute;
  top: 5px;
  left: -19px;
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-radius: 100%;
  border-style: solid; }

.legend-component__title:not(:last-child) {
  margin-right: 58px; }

.dashboard {
  padding-top: 40px; }

.dashboard-card {
  font-size: 0;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(74, 83, 114, 0.11);
  border-radius: 4px; }

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

div.dashboard-card.loading {
  background: transparent;
  box-shadow: none;
  position: relative;
  margin-top: 0;
  padding: 0;
  height: calc(100vh - 510px);
  min-height: 250px; }

.dashboard-card:not(:first-child) {
  margin-top: 40px; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.small .bottom-row {
  padding-right: 50px; }

.small .graph__title {
  height: 35px; }

.small div.graph {
  width: 280px;
  display: inline-block;
  vertical-align: top;
  height: 267px; }

.small .dropdown-menu,
.small .dropdown-title-single {
  position: absolute;
  bottom: -33px;
  left: 30px; }

.small .chart-legend {
  display: none; }

.help-icon__main-container {
  display: inline-block;
  border: 1px solid #e3e3e3;
  padding: 16px 16px 16px 35px;
  background-color: #fff;
  position: absolute;
  right: 4px;
  top: 27px;
  border-radius: 4px;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  opacity: 0;
  z-index: -9999; }

.help-container {
  position: relative; }

.help-icon__triangle {
  position: relative; }
  .help-icon__triangle::after {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: -24px;
    border-top: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
    left: -24px; }

.help-icon {
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
  position: absolute;
  right: -24px;
  top: 1px; }
  .help-icon path {
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease; }

.help-icon:hover path {
  fill: #a1a9bc;
  fill-opacity: 1; }

.help-icon:hover + .help-icon__main-container {
  z-index: 1;
  opacity: 1; }

.graph {
  width: 630px;
  position: relative; }
  .graph.xbig, .graph.large {
    width: 100%;
    float: none; }
    .graph.xbig:not(:last-child), .graph.large:not(:last-child) {
      margin-bottom: 50px; }

.graph:first-child {
  float: left; }

.graph:last-child {
  float: right; }

.graph__title {
  font-size: 14px;
  padding-left: 30px;
  line-height: 18px;
  color: #000;
  float: left;
  z-index: 999;
  position: relative; }
  .graph__title .no-wrap {
    font-weight: inherit;
    font-size: inherit;
    color: inherit; }

tspan {
  font-family: Arial, sans-serif;
  color: #a5aebd;
  font-size: 11px;
  line-height: 15px; }

.dashboard-card__top-row {
  height: 400px; }

.map-container {
  width: auto; }

.map-container.active {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 9999999; }

.header-button:disabled {
  color: #aaa; }

.header-button:disabled path {
  fill: #ccc; }

.header-button path {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.header-button.initialFiles {
  margin-right: 61px; }

.header-button.initialFiles::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -10px;
  width: 1px;
  height: 20px;
  right: -30px;
  background-color: #ced3d6; }

.header-button.remove:hover path {
  fill: #676f89; }

.header-button.remove:active path {
  fill: #3e4660; }

.header-button:not(:disabled).pdf:hover path {
  fill: #e25529; }

.header-button:not(:disabled).pdf:active path {
  fill: #ba2d00; }

.header-button:not(:disabled).excel:hover path {
  fill: #299e4a; }

.header-button:not(:disabled).excel:active path {
  fill: #007521; }

.common-button,
.header-button {
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #333333;
  padding: 0 0 0 24px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent; }

.button-icon {
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  position: absolute;
  background-size: contain;
  background-position: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-repeat: no-repeat; }

.clearfix {
  clear: both; }

.header {
  font-size: 0; }

.header-buttons {
  float: right;
  padding-top: 1px;
  padding-right: 1px;
  line-height: 29px; }

.header-button.excel {
  margin-left: 50px;
  margin-right: 85px; }

.header-button.export-pdf::before {
  background-image: url(/files/static/media/pdf.492ce110.svg); }

.header-button.excel::before {
  background-image: url(/files/static/media/excel.0ccac739.svg); }

.header-button.remove::before {
  background-image: url(/files/static/media/remove.097028d5.svg); }

.header-button.excel::after {
  content: '';
  top: 50%;
  width: 1px;
  right: -31px;
  height: 20px;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #ced3d6; }

.top-row-graph {
  padding-top: 30px;
  margin-right: 10px; }

.recharts-default-tooltip li {
  font-size: 14px;
  line-height: 18px;
  color: #333333; }

.recharts-default-tooltip p {
  font-size: 14px;
  color: #6f7a98;
  line-height: 18px; }

.tooltip-circle,
.tooltip-text {
  display: inline-block;
  vertical-align: middle; }

.tooltip-circle {
  width: 10px;
  height: 10px;
  line-height: 18px;
  margin-right: 4px;
  border-radius: 50%;
  border: 2px solid #23d692; }

.recharts-default-tooltip {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e3e3e3 !important;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
  padding: 15px 20px !important; }

.tooltip-component {
  font-size: 34px; }

.dropdown-root {
  float: right;
  color: #6f7a98;
  cursor: pointer;
  z-index: 99999;
  position: relative;
  margin-right: 30px; }

.dropdown-root.top-row-sort {
  display: inline-block;
  float: none;
  vertical-align: middle;
  margin-right: 61px;
  text-align: left;
  position: relative;
  color: #333333; }

.dropdown-root.top-row-sort::before {
  content: '';
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: -24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-image: url(/files/static/media/sortBy.c653e608.svg); }

.dropdown-root.top-row-sort::after {
  content: '';
  top: 50%;
  width: 1px;
  right: -31px;
  height: 20px;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #ced3d6; }

.dropdown-root.top-row-sort .dropdown-title {
  width: 97px;
  text-align: left; }

.Dropdown-control {
  overflow: hidden;
  padding: 0px 20px;
  position: relative;
  background-color: white;
  box-sizing: border-box;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }

.Dropdown-arrow-wrapper {
  top: 50%;
  right: 0;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.Dropdown-root.is-open .Dropdown-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.Dropdown-arrow {
  display: block;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-image: url(/files/static/media/menu-down.e817e5b2.svg); }

.dropdown-title {
  width: 150px;
  font-weight: 500;
  text-align: right;
  font-size: 14px;
  line-height: 18px;
  padding-right: 20px; }

.scrollarea-content {
  margin: 0;
  padding: 0;
  position: absolute;
  min-width: 100%;
  min-height: 100%; }

.scrollarea {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: 300px; }

.consumption-graph .scrollarea {
  max-height: none; }

.scrollarea-content:focus {
  outline: 0; }

.scrollarea.scrollarea-logs {
  height: calc(100% - 49px); }

.scrollarea .scrollbar-container {
  position: absolute;
  background: none;
  opacity: 1;
  z-index: 9999; }

.scrollarea .scrollbar-container.horizontal {
  width: 100%;
  left: 0;
  bottom: 0;
  padding-top: 4px;
  padding-bottom: 0;
  cursor: pointer; }

.scrollarea .scrollbar-container.horizontal .scrollbar {
  height: 4px;
  position: relative;
  background-color: transparent; }

.scrollarea .scrollbar-container.horizontal .scrollbar::after {
  content: '';
  position: absolute;
  background: #aaa;
  border-radius: 4px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 8px; }

.scrollarea .scrollbar-container.vertical {
  height: 100%;
  width: 10px;
  right: 0;
  top: 0; }

.scrollarea .scrollbar-container.vertical .scrollbar {
  width: 4px;
  height: 20px;
  min-height: 18px;
  position: relative;
  background-color: transparent; }

.scrollarea .scrollbar-container.vertical .scrollbar::after {
  content: '';
  position: absolute;
  background: #202a3842;
  right: 0;
  min-height: 16px;
  border-radius: 4px;
  left: 0;
  top: 15%;
  bottom: 15%; }

.dropdown-menu {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.17);
  box-sizing: border-box;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  top: 100%;
  width: 180px;
  height: 180px;
  z-index: 1000;
  right: -20px;
  -webkit-overflow-scrolling: touch; }

.active .dropdown-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.dropdown-arrow {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 1px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(/files/static/media/menu-down.e817e5b2.svg); }

.Dropdown-menu::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: -1;
  top: 8px;
  right: 35px;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
  background-color: #fff; }

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  font-weight: bold;
  text-transform: capitalize; }

.dropdown-option {
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  min-width: 180px;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  box-sizing: border-box; }

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.Dropdown-option:hover {
  background-color: rgba(178, 193, 230, 0.138); }

.Dropdown-option.is-selected {
  background-color: rgba(178, 193, 230, 0.138); }

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px; }

.fade-enter {
  opacity: 0.01; }

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 3s ease-in;
  transition: opacity 3s ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.cssload-loader {
  position: relative;
  width: 100px;
  height: 100px;
  margin-top: 50px;
  perspective: 780px;
  left: calc(50% - 50px);
  -webkit-perspective: 780px; }

.cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%; }

.cssload-inner.cssload-one {
  left: 0%;
  top: 0%;
  -webkit-animation: cssload-rotate-one 1.15s linear infinite;
          animation: cssload-rotate-one 1.15s linear infinite;
  border-bottom: 3px solid #019ddc; }

.cssload-inner.cssload-two {
  right: 0%;
  top: 0%;
  -webkit-animation: cssload-rotate-two 1.15s linear infinite;
          animation: cssload-rotate-two 1.15s linear infinite;
  border-right: 3px solid #23d692; }

.cssload-inner.cssload-three {
  right: 0%;
  bottom: 0%;
  -webkit-animation: cssload-rotate-three 1.15s linear infinite;
          animation: cssload-rotate-three 1.15s linear infinite;
  border-top: 3px solid #0079c1; }

@keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); } }

@-webkit-keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); } }

@keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); } }

@-webkit-keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); } }

@keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); } }

@-webkit-keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg); }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg); } }

.loader-second-text,
.loader-text {
  font-size: 14px;
  text-align: center;
  line-height: 18px;
  color: #6f7a98; }

.loader-second-text {
  margin-top: 4px; }

.loader-text {
  margin-top: 20px; }

[class*='hint'] {
  padding: 0 !important;
  background: transparent !important;
  box-shadow: none; }

.icon-label-container {
  position: relative;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.icon-label {
  font-size: 14px;
  line-height: 18px;
  color: #4a5372;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(-95%, 0);
          transform: translate(-95%, 0);
  text-shadow: -1px 0 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff; }

.h-content {
  position: relative;
  border-radius: 3px;
  z-index: 9;
  padding: 6px 12px;
  background: rgba(25, 36, 51, 0.8); }

.h-content__text {
  font-size: 12px;
  line-height: 16px;
  color: #ffffff; }

.table-row-name-0 {
  color: #333333;
  font-weight: 500; }

.recharts-tooltip-label {
  font-weight: 500;
  margin-bottom: 5px !important; }

.tooltip-text {
  font-weight: 500;
  color: #333333; }

div.icon-label-container {
  width: 42px;
  height: 42px; }

div.icon-label-container.crossroad {
  width: 28px;
  height: 28px; }

.recharts-tooltip-wrapper.recharts-tooltip-wrapper-right::after {
  left: -12px;
  border-left: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3; }

.recharts-tooltip-wrapper.recharts-tooltip-wrapper-left::after {
  right: -2px;
  border-top: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3; }

.recharts-tooltip-wrapper::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  -webkit-transform: rotate(45deg) translateY(-50%);
          transform: rotate(45deg) translateY(-50%);
  background-color: #fff; }

.map-button-container {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(74, 74, 74, 0.16);
  border-radius: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  z-index: 99999; }

.active .resize-map {
  background-image: url(/files/static/media/minimize.6e971d73.svg); }

.resize-map {
  padding: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(/files/static/media/maximaize.35b3e05f.svg); }

.to-center {
  padding: 0;
  width: 16px;
  height: 16px;
  display: block;
  background-color: #ffffff;
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-image: url(/files/static/media/moveToSelection.0cbd0341.svg);
  margin-bottom: 13px;
  position: relative; }

.to-center::after {
  background: #f6f6f6;
  width: 20px;
  height: 1px;
  content: '';
  position: absolute;
  left: -2px;
  bottom: -7px; }

.splash-title {
  position: absolute;
  font-size: 32px;
  line-height: 24px;
  margin-top: -12px;
  z-index: 99999;
  color: #d7d7d7;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%); }

.graph__title {
  font-weight: 500; }

.dropdown-title-single {
  float: right;
  color: #6f7a98;
  font-size: 14px;
  line-height: 18px;
  padding-right: 40px;
  font-weight: 500; }

.dropdown-title-single::first-letter,
.Dropdown-placeholder::first-letter {
  text-transform: uppercase; }

.no-wrap {
  white-space: nowrap; }

.cursor-pointer {
  cursor: pointer; }

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto; }

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute; }

.scrollbar-container .ps__rail-y {
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute; }

.scrollbar-container .ps__rail-y::after {
  background-color: transparent; }

.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.9; }

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute; }

.ps__thumb-y {
  /* there must be 'right' for ps__thumb-y */
  right: 6px;
  /* please don't change 'position' */
  position: absolute;
  width: 4px;
  background: #202a38;
  border-radius: 4px; }

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  /* height: 11px; */ }

.scrollbar {
  max-height: 200px; }

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important; } }

.area {
  margin: 0 auto;
  height: 300px;
  background: #e5e5e5; }

.area .content .item {
  background: #82bb95;
  width: 180px;
  height: 70px;
  margin: 10px;
  float: left;
  padding: 8px;
  box-sizing: border-box; }

.close-button {
  float: right;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: center;
  border-radius: 50%;
  margin-right: 30px;
  background-color: transparent;
  background-image: url(/files/static/media/close.56179d70.svg); }

.active-facility-table .scrollbar,
.active-facility-table .scrollarea {
  max-height: none; }

.active-facility-table:not(.fullScreen) .scrollarea-content {
  position: relative; }

.active-facility-table-title {
  font-size: 11px;
  color: #a5aebd;
  line-height: 15px;
  text-align: left; }

.active-facility-table-title th {
  padding-bottom: 11px;
  border-bottom: 1px solid #f2f2f2; }

.active-facility-table-title th:not(:last-child) {
  padding-right: 16px; }

.active-facility-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 17px; }

.table-subcontainer {
  background-color: #f9fbfd;
  width: calc(50% - 10px);
  border-radius: 4px;
  padding: 32px;
  margin-bottom: 20px;
  display: inline-block; }

.table-subcontainer:nth-child(2n + 1):not(:last-child) {
  margin-right: 20px; }

.table-color-line {
  padding-bottom: 16px; }

.active-facility-percentage-line-color {
  height: 10px;
  display: inline-block;
  vertical-align: middle; }

.active-facility-percentage-line-color:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px; }

.active-facility-percentage-line-color:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px; }

.active-facility-percentage-line-color:not(:last-child) {
  margin-right: 2px; }

.active-facility-table-row th:not(:last-child):not(:first-child):not(.name) {
  padding-right: 22px;
  white-space: nowrap; }

.consumer .active-facility-table-row th:first-child {
  padding-right: 12px; }

.active-facility-value-color {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-left: 14px;
  font-weight: 500; }

.active-facility-value-color.with-circle::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%; }

.active-facility-value-color.c-0 {
  color: #0079c1; }

.active-facility-value-color.with-circle.c-0::before {
  border: 2px solid #0079c1; }

.active-facility-color-line.c-0,
.active-facility-percentage-line-color.c-0 {
  background-color: #0079c1; }

.active-facility-value-color.c-1 {
  color: #15b6ea; }

.active-facility-value-color.with-circle.c-1::before {
  border: 2px solid #15b6ea; }

.active-facility-color-line.c-1,
.active-facility-percentage-line-color.c-1 {
  background-color: #15b6ea; }

.active-facility-value-color.c-2 {
  color: #23d692; }

.active-facility-value-color.with-circle.c-2::before {
  border: 2px solid #23d692; }

.active-facility-color-line.c-2,
.active-facility-percentage-line-color.c-2 {
  background-color: #23d692; }

.active-facility-value-color.c-3 {
  color: #82d100; }

.active-facility-value-color.with-circle.c-3::before {
  border: 2px solid #82d100; }

.active-facility-color-line.c-3,
.active-facility-percentage-line-color.c-3 {
  background-color: #82d100; }

.active-facility-value-color.c-4 {
  color: #fab400; }

.active-facility-value-color.with-circle.c-4::before {
  border: 2px solid #fab400; }

.active-facility-color-line.c-4,
.active-facility-percentage-line-color.c-4 {
  background-color: #fab400; }

.active-facility-value-color.c-5 {
  color: #ff8e56; }

.active-facility-value-color.with-circle.c-5::before {
  border: 2px solid #ff8e56; }

.active-facility-color-line.c-5,
.active-facility-percentage-line-color.c-5 {
  background-color: #ff8e56; }

.active-facility-value-color.c-6 {
  color: #dc4d58; }

.active-facility-value-color.with-circle.c-6::before {
  border: 2px solid #dc4d58; }

.active-facility-color-line.c-6,
.active-facility-percentage-line-color.c-6 {
  background-color: #dc4d58; }

.active-facility-value-color.c-7 {
  color: #dc4dab; }

.active-facility-value-color.with-circle.c-7::before {
  border: 2px solid #dc4dab; }

.active-facility-color-line.c-7,
.active-facility-percentage-line-color.c-7 {
  background-color: #dc4dab; }

.active-facility-value-color.c-8 {
  color: #944ddc; }

.active-facility-value-color.with-circle.c-8::before {
  border: 2px solid #944ddc; }

.active-facility-color-line.c-8,
.active-facility-percentage-line-color.c-8 {
  background-color: #944ddc; }

.active-facility-value-color.c-9 {
  color: #6a4ddc; }

.active-facility-value-color.with-circle.c-9::before {
  border: 2px solid #6a4ddc; }

.active-facility-color-line.c-9,
.active-facility-percentage-line-color.c-9 {
  background-color: #6a4ddc; }

.active-facility-table-value {
  font-size: 14px;
  line-height: 17px;
  color: #6f7a98;
  text-align: right;
  vertical-align: top; }

.active-facility-table-value.name {
  padding-right: 16px; }

.active-facility-table-value.name,
.active-facility-table-value.left {
  text-align: left; }

.active-facility-table-value:not(:first-child) {
  font-weight: 500; }

.active-facility-table-row:first-child th {
  padding-top: 16px; }

.active-facility-table-row th:first-child {
  text-align: left; }

.active-facility-table-row:not(:last-child) th {
  padding-bottom: 12px; }

.active-facility-value {
  display: inline-block;
  vertical-align: top; }

.active-facility-color-line-container {
  display: inline-block;
  width: 160px;
  height: 8px;
  background-color: rgba(201, 204, 213, 0.3);
  border-radius: 100px; }

.active-facility-color-line {
  height: 8px;
  display: block;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px; }

.active-facility-table-title th.producer-title {
  font-size: 14px;
  color: #000000;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  padding-right: 0; }

.producer .active-facility-table-title {
  text-align: left; }

.active-facility-table.bottom {
  padding-bottom: 20px; }

.producer .active-facility-table-value:first-child {
  padding-right: 12px; }

.table-subcontainer table {
  width: 100%; }

.fullScreen.dashboard-card {
  position: fixed;
  top: -40px;
  right: 0;
  bottom: 0;
  width: 640px;
  z-index: 9999999;
  overflow: auto; }

.fullScreen.dashboard-card .table-subcontainer {
  width: 100%; }

.active .map-button-container.activeFacility {
  right: 656px; }

.fullScreen.dashboard-card thead .active-facility-table-title th:last-child {
  width: 98px;
  text-align: left; }

.hideCrossroad .crossroad {
  opacity: 0; }

.hideFacilities .producer,
.hideFacilities .consumer,
.hideFacilities .storage {
  opacity: 0; }

.cargo-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 20px; }

.modal-results {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ced3d6;
  border-radius: 4px;
  padding: 22px; }

.modal.cargo-modal {
  margin-top: 40px; }

.modal.cargo-modal .modal-results {
  cursor: pointer; }

.cargo-modal__sub-title {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 37px;
  line-height: 16px;
  color: #999999; }

.cargo-modal__desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #019ddc;
  padding-left: 24px;
  position: relative; }

.cargo-modal__desc::after {
  content: '';
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/files/static/media/desc.1642b46a.svg); }

.auth-container {
  position: relative;
  min-height: 100vh; }

.auth-subcontainer {
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(74, 83, 114, 0.08);
  border-radius: 4px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 40px 40px 60px;
  position: absolute; }

.auth-title {
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  line-height: 29px;
  margin-bottom: 32px;
  text-align: center; }

.auth-label {
  display: block; }

.auth-label-text {
  color: #999999;
  display: block;
  font-size: 14px;
  margin-bottom: 8px; }

.auth-input {
  padding: 8px 16px;
  font-size: 14px;
  color: #333333;
  border-radius: 4px;
  display: block;
  height: 48px;
  border: 1px solid #ced3d6;
  width: 280px; }

.auth-fields {
  padding-bottom: 30px; }
  .auth-fields .auth-label:not(:last-child) {
    margin-bottom: 20px; }

.auth-button {
  width: 100%; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a {
  cursor: pointer; }

a:focus {
  outline: none; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
  border: none;
  outline: none; }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

* {
  font-family: 'DIN Pro';
  font-weight: normal;
  box-sizing: border-box; }

html {
  background: #f4f7fb; }

@media (min-width: 1300px) {
  html::-webkit-scrollbar {
    width: 0;
    height: 0; } }

.sub-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 29px;
  color: #a5aebd;
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle; }

/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:35 AM */

@font-face {
    font-family: 'MontserratAlternates';
    src: local('MontserratAlternates'),
    url(/files/static/media/MontserratAlternates-Bold.b466c53c.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url(/files/static/media/DINPro.eef40e95.eot);
    src: local('DIN Pro'), local('DINPro'),
    url(/files/static/media/DINPro.eef40e95.eot?#iefix) format('embedded-opentype'),
    url(/files/static/media/DINPro.508963e1.woff) format('woff'),
    url(/files/static/media/DINPro.1184eb6d.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url(/files/static/media/DINPro-Medium.dab3b32b.eot);
    src: local('DIN Pro Medium'), local('DINPro-Medium'),
    url(/files/static/media/DINPro-Medium.dab3b32b.eot?#iefix) format('embedded-opentype'),
    url(/files/static/media/DINPro-Medium.393be0a3.woff) format('woff'),
    url(/files/static/media/DINPro-Medium.d1265164.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url(/files/static/media/DINPro-Bold.a9d06d03.eot);
    src: local('DIN Pro Bold'), local('DINPro-Bold'),
    url(/files/static/media/DINPro-Bold.a9d06d03.eot?#iefix) format('embedded-opentype'),
    url(/files/static/media/DINPro-Bold.8428dd40.woff) format('woff'),
    url(/files/static/media/DINPro-Bold.c0e8cc5a.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}
