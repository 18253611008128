.modal {
  width: 672px;
  padding: 40px;
  border-radius: 4px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(74, 83, 114, 0.08);
}

.modal__title {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 31px;
}

.modal-dropzone-title {
  color: #999999;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.modal__dropzone-remove-button {
  float: right;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 23px;
  right: 23px;
  padding: 0;
  font-size: 0;
  background-color: transparent;
}
.modal__dropzone-remove-button path {
  transition: all 0.25s ease;
}
.modal__dropzone.showLoader {
  cursor: default;
}
.modal__dropzone {
  width: 592px;
  height: 60px;
  margin-top: 9px;
  cursor: pointer;
  border-radius: 4px;
  padding: 20px 22px 20px 21px;
  margin-bottom: 30px;
  border: 1px dashed #b4c4d7;
  transition: all 0.5s ease;
}
.modal__dropzone:hover {
  border: 1px solid #b4c4d7;
}
.modal__dropzone.isDragActive {
  border: 1px solid #97a5b5;
}

.modal__dropzone.active {
  border: 1px solid #ced3d6;
}

.modal__dropzone-title-container {
  text-align: center;
  font-size: 0;
}

.modal__dropzone-title-container.active {
  text-align: left;
}

.modal__dropzone-title {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: -0.1px;
}

.modal__dropzone-title-container.active .modal__dropzone-title-icon {
  background-image: url(../../../assets/images/dropzone-active.svg);
}

.modal__dropzone-title-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../../assets/images/dropzone-file.svg);
  margin-right: 8px;
}

.firstpage-loader {
  height: calc(100vh - 480px);
  position: relative;
}
.modal-top-title {
  font-size: 14px;
  line-height: 16px;
  color: rgba(111, 122, 152, 0.5);
  margin-bottom: 20px;
  font-weight: 500;
}
.modal-top-row {
  width: 672px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 20px;
}
.modal-switch-button {
  box-shadow: 0px 0px 32px rgba(74, 83, 114, 0.08);
  border-radius: 4px;
  background-color: #f4f7fb;
  color: #a5aebd;
  transition: all 0.25s ease;
  width: 326px;
  text-align: center;
  padding: 20px 0;
}
.modal-switch-button:hover {
  background-color: #fff;
}

.modal-switch-button svg {
  vertical-align: middle;
  margin-right: 12px;
}
.modal-switch-button span {
  vertical-align: middle;
  font-weight: bold;
}
.modal-switch-button.active {
  background-color: #fff;
  color: #0079c1;
}

.modal-switch-button path {
  fill: #a5aebd;
  transition: all 0.25s ease;
}

.modal-switch-button.active path {
  fill: #0079c1;
}

.modal-button {
  width: 290px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 11px 0;
  background-color: transparent;
  border-radius: 4px;
  transition: all 0.25s ease;
  color: #a5aebd;
}
.modal-button:hover {
  background-color: #fff;
}

.modal-button.active {
  color: #0079c1;
  transition: all 0.25s ease;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px rgba(74, 83, 114, 0.08);
}
.modal-buttons-row {
  background: #f4f7fb;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 24px;
}
.project-number-input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ced3d6;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  font-size: 14px;
  color: #333333;
  line-height: 17px;
  padding: 15px 16px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
}

.get-example-button.common-button {
  float: right;
  line-height: 48px;
}

.get-example-button.common-button .button-icon {
  line-height: 25px;
}
