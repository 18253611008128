.alert-subtitle {
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
}

.alert-title {
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
}

.alert-title,
.alert-desc {
    color: #333333;
}

.alert-desc::first-letter {
    text-transform: uppercase;
}

.alert-desc {
    font-size: 14px;
    line-height: 24px;
}

.alert-desc-medium {
    font-weight: 500;
}

.description-container:not(:last-child) {
    margin-bottom: 16px;
}

.alert {
    background: #FFFFFF;
    box-shadow: 0 0 32px rgba(74, 83, 114, 0.08);
    border-radius: 4px;
    font-size: 0;
    width: 672px;
    margin: 0 auto;
    padding: 24px;
    position: relative;
}

.alert::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 4px;
    border-radius: 4px;
}

.alert.warning::before {
    background-color: #FFD15B;
}

.alert.error::before {
    background-color: #FF5B5B;
}

.alert-icon {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    position: relative;
    margin-right: 16px;
    box-shadow: 0 0 32px rgba(74, 83, 114, 0.08);
}

.alert-icon,
.alert-text {
    display: inline-block;
    vertical-align: top;
}

.alert-text {
    max-width: calc(100% - 48px);
}

.warning .alert-icon {
    background-image: url(../../../assets/images/warning.svg);
}

.error .alert-icon {
    background-image: url(../../../assets/images/error.svg);
}

.alert-container {
    padding-top: 40px;
}

.alert-title {
    margin-bottom: 8px;
}